@import '../../styles/variables.scss';

.forms-controller {
  display: grid;
  grid-column: span 4;

  @media only screen and (min-width: $breakpoint-md) {
    grid-column-start: 1;
    grid-column-end: 9;
  }

  @media only screen and (min-width: $breakpoint-lg) {
    grid-column-start: 1;
    grid-column-end: 9;
  }
}
