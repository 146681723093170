@import 'site';
@import 'mixins';
@import 'variables';
@import 'breakpoints';

// @import "../components/**/*.scss";
@import '../../node_modules/@in/component-library/dist/component-library.css';

* {
  font-feature-settings: 'tnum', 'calt', 'kern';
  font-variant-numeric: tabular-nums;
}

.app-body {
  .app-content {
    margin: 0 16px;
  }
}

.card {
  padding: 32px;
  @include breakpoint-sm-max {
    padding: 16px;
  }
}

.pageLoader {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	padding-bottom: 12rem;
  grid-column-start: 1;
  grid-column-end: 13;
}

.noWrap {
  white-space: nowrap;
}

h3, p {
  margin: 0;
  padding: 0;
  margin-bottom: 1rem;
}

fieldset {
  margin: 0;
  padding: 0;
}

.linkButton {
  border: none;
  text-decoration: none;
  background-color: transparent;
  text-align: left;
  font-size: inherit;
  font-family: inherit;
  padding: 0;

  &:hover,
  &:focus {
    text-decoration: underline;
    outline: none;
  }
}