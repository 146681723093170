.form-introduction {
  & h2 {
    margin-top: 2rem;
  }
  &__actions {
    margin-top: 2rem;

    @media screen and (min-width: 48em) {
      margin-top: 5rem;
    }
  }

  & button {
    width: 100%;
    @media screen and (min-width: 48em) {
      width: 10rem;
    }
  }

  & > img {
    width: 9.375rem;
    height: auto;
  }
}
