.card {
	display: flex;
	height: 150px;
	background: #fff;
	color: #000;
	box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
	border-radius: 8px;
	margin-bottom: 1rem;
	cursor: pointer;

	&__wrapper {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		width: 100%;
		font-size: 16px;
	}

	&__content-main {
		display: flex;
		flex-direction: column;
	}

	&__title {
		font-size: 20px !important;
		line-height: 24px !important;
		font-weight: 700 !important;
		margin: 0;
		order: 1;
	}

	&__secondary-title {
		line-height: 19px;
		margin: 0;
		order: -1;
		&-below {
			order: 2;
		}
	}

	&__icon-left {
		float: left;
		margin-top: 1px;
		margin-right: 10px;
	}

	&__icon-right {
		float: right;
	}

	&__content-body {
		height: 100%;
		position: relative;
	}

	&__label-icon {
		margin-right: 7px;
		width: 18px;
	}

	&__children {
		position: absolute;
		bottom: 0;
		z-index: 2;
		width: 100%;
	}

	&__content {
		position: absolute;
		bottom: 0;
		display: flex;
		justify-content: space-between;
		width: 100%;
		z-index: 1;
	}

	&__tag {
		float: right;
		margin-top: 1px;
		padding: 2px 4px;
	}

	&__tag-bg {
		background: #f2f2f2;
		border-radius: 4px;
		font-size: 12px;
		line-height: 14px;
		text-transform: uppercase;
		letter-spacing: 0.05em;
		float: right;
		margin-top: 1px;
		padding: 2px 4px;
	}
}
