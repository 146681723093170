.page-action {
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 48em) {
    flex-direction: row;
  }

  .page-action-button-right {
    margin: 0;
    @media screen and (min-width: 48em) {
      margin-left: auto;
    }
  }

  .page-action-button-left {
    margin: 0 0 1rem;
    @media screen and (min-width: 48em) {
      margin: 0 auto 0 0;
    }
  }
}

.progressbar {
  margin: 0 0 1.937rem 0;
}

.notification {
  margin: 1rem 0;
}

.hide-node {
  display: none;
}

.description {
  margin-bottom: 2rem;
}

.description a:visited {
  color: #5100b8;
}

.stepper-mobile {
  position: sticky;
  top: 34px;
  z-index: 1;
  margin-bottom: 2rem;
  background-color: white;
}

.dropdown {
  > div:first-of-type > div > ul > li:focus {
    outline: none;
  }
  > div:last-child {
    display: none;
  }
}

.dropdown-label {
  display: flex;
  justify-content: space-between;

  > p {
    margin-bottom: 2rem;
  }
  > p:last-child {
    font-weight: 700;
  }
}
