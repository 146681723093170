.form-field {
  margin-bottom: 1.375rem;
}

.textAreaFieldWrapper {
  margin-bottom: 3.313rem;
}

.textFieldWrapper {
  margin-bottom: 3.313rem;
  margin-top: 3rem;
}

.fileUploadFieldWrapper {
  margin-top: 3rem;
  margin-bottom: -0.5rem;
} 

.form-field.groupFieldWrapper {
  margin: 0rem 0rem 1.5rem;
}
.form-field.inlineReadOnlyTextFieldWrapper {
  margin: 0rem 0rem 0.3rem;
}

.form-field > fieldset > legend {
  margin-bottom: 1rem;
}
