.fileUploadSummaryField {
  ul {
    list-style-type: none;

    li {
      margin-bottom: 0.5rem;
    }
  }
}

.link-button-icon {
  padding-bottom: 3px;
  position: relative;
  top: -2px;
}
