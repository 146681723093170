.VMA-partners {
  display: grid;
  grid-column-start: 1;
  grid-column-end: 13;
  width: 100%;

  &__grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 0 1rem;

    @media screen and (min-width: 48em) {
      grid-template-columns: repeat(8, 1fr);
    }

    @media screen and (min-width: 64em) {
      grid-template-columns: repeat(12, 1fr);
      grid-gap: 0 1.5rem;
    }
  }

  &__textblock {
    display: grid;
    grid-column-start: 1;
    grid-column-end: 13;
    margin-bottom: 2.5rem;

    @media screen and (min-width: 48em) {
      grid-column-end: 9;
    }

    h1 {
      margin-top: 0;
    }

    p {
      margin-bottom: 0;
    }
  }

  &__tabs-container {
    display: grid;
    grid-column-start: 1;
    grid-column-end: 13;
  }

  &__tabs {
    margin-bottom: 3rem;

    // Tab-group
    & > div {
      flex-wrap: wrap;

      // tab
      & > button {
        margin-top: 0.5rem;
      }

      // blank-cell
      & > div {
        border-bottom: none;

        @media screen and (min-width: 48em) {
          border-bottom: 1px #f3f1ef solid;
        }
      }
    }
  }

  &__tabpanel-title {
    margin-bottom: 0;
    margin-top: 0;
    @media screen and (min-width: 48em) {
    }
  }

  &__card-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 1.5rem 2.5rem;

    list-style: none;

    padding: 0;
    margin-top: 2rem;
    margin-bottom: 0;

    @media screen and (min-width: 48em) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 8.25rem;

    padding: 1.5rem;

    @media screen and (min-width: 48em) {
      min-height: 12.75rem;
    }

    @media screen and (min-width: 64em) {
      min-height: 11.125rem;
    }
  }

  &__card-title {
    margin-bottom: 0.5rem;

    font-weight: 400;
    line-height: 1.5rem;

    overflow-wrap: break-word;
    hyphens: auto;
  }

  &__card-text {
    font-size: 1rem;
    margin: 0;
    padding: 0;

    @media screen and (min-width: 48em) {
      font-size: 1.25rem;
    }
  }

  &__card-link-container {
    margin-top: 0.5rem;
  }

  &__button-container {
    display: grid;
    grid-column-start: 1;
    grid-column-end: 13;
  }

  &__button {
    width: 100%;
    margin-top: 3rem;

    &--dense {
      width: auto;
    }

    @media screen and (min-width: 48em) {
      width: auto;
    }
  }

  &__modal-text {
    margin-top: 1.6rem;
    margin-bottom: 3rem;
  }
}
