@import '../styles/variables.scss';

.app-section {
  margin-top: 3rem;
}

.layout-mainContent {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.base-header {
  margin-bottom: 0;
  z-index: 2;
  position: relative;
}

html:not([data-scroll='0']) {
  .save-bar__fixed {
    position: fixed;
    width: 100vw;
    background-color: #f2f2f2;
    top: 0;
  }
}

.layout-content {
  flex: 1 0 auto;
}

.layout-footer {
  flex-shrink: 0;
}

.top-element {
  width: 0;
  height: 0;
}
