div[role='alert'] {
  margin-top: 0.5rem;

  ol li ul {
    padding-top: 0.5rem;
  }
  ol li {
    padding-bottom: 0.5rem;
  }
}

div[role='alert'] div > a:hover,
a:active,
a:focus {
  text-decoration-line: underline !important;
}

.formSummaryValidation {
  margin-top: 1rem;
  margin-bottom: 1rem;

  a:hover,
  a:active,
  a:focus {
    text-decoration-line: underline;
  }

  ol {
    margin-top: 0;
    margin-bottom: 0;
    padding-inline-start: 20px;

    ul {
      padding-inline-start: 20px;
      list-style-type: square;

      margin-bottom: 0.5rem;
    }
  }

  li span {
    vertical-align: top;
  }
}

.remove-button-styling {
  border: 0;
  width: inherit;
  border-radius: 0;
  overflow: hidden;
  float: initial;
  height: auto;
  display: inline-block;
  text-align: left;
  outline-offset: 0;
  text-decoration: none;
  transform: translateZ(0);
  background-color: transparent;
  padding: 0;
  margin: 0;
  letter-spacing: inherit;
  line-height: inherit;

  font-size: 16px;
  color: #0060b8;
  text-decoration: underline;
  cursor: pointer;
}

.displayValidationErrorText {
  margin-top: 8px;
}