.skip-to-content ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.skip-link {
  font-family: "InnovationNorway", Helvetica, Arial, sans-serif;
  position: absolute;
  color: #fff;
  text-decoration: none;
  background-color: #000;
  padding: 1rem;
  opacity: 0;
  max-width: 120px;
  border-radius: 0 0 0.5rem 0.5rem;
  z-index: 3;
  @media screen and (min-width: 75em) {
      border-radius: 0 0 0.5rem;
      transform: translateY(-120%);
      transition: transform 0.3s;
      max-width: 200px;
    }

    &:focus {
      transform: translateY(0%);
      opacity: 1;
      color: #fff;
      text-decoration-line: none!important;
    }

    & > ul {
      list-style: none;
      text-decoration: none;

    }
}
