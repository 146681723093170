h2.h2AdjustedForSectionedPage {
  margin: 0rem 0rem 2rem;
  font-size: 2rem;
  line-height: 2rem;

  @media screen and (min-width: 48em) {
    font-size: 3rem;
    margin: 0rem 0rem 3rem;
  }
}

h3.h3AdjustedForSectionedFormPage {
  font-size: 1.5rem;
  margin-bottom: 1.25rem;

  @media screen and (min-width: 48em) {
    font-size: 2rem;
    margin-bottom: 2rem;
  }
}

.description,
.description p {
  margin-bottom: 1.25rem;

  @media screen and (min-width: 48em) {
    margin-bottom: 2rem;
  }
}
