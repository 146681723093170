@import '../../styles/variables.scss';

h4,
h3,
p {
  margin: 0;
  padding: 0;
}

.form-status {
  display: grid;
  grid-column: span 6;
  grid-column-start: 1;
  grid-column-end: 12;

  &__case-process {
    @media only screen and (min-width: $breakpoint-lg) {
      display: grid;
      grid-template-columns: 1fr 2.8fr;
      column-gap: 5.6rem;
      margin-right: 3rem;
    }
  }

  &__communication--body {
    margin-bottom: 1rem;
  }

  &__title {
    margin-top: 0;
    grid-column: 2; 
  }

  &__uxsignals {
    margin-bottom: 2.5rem;
    > div {
      > div {
        > div {
          > div {
            padding-top: 1rem;
            padding-bottom: 0;
          }
        }
      }
    }
  }
}

.form-status-menu {
  display: flex;
  flex-direction: column;
  margin-top: 3rem;

  @media (min-width: $breakpoint-md) {
    margin-bottom: 1.5rem;
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 0 0 2.5rem;

    @media (min-width: $breakpoint-md) {
      margin-bottom: 3rem;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-size: 16px;
    padding: 0.5rem 0.5rem;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }

    &--active {
      font-size: $text-size--normal;
      background-color: $in--blue-25;
    }
  }

  &__icon {
    font-size: 1rem;
  }

  &__link {
    all: unset;
    text-decoration: none;
    font-weight: $font-weight--bold;
    padding-left: 0.5rem;

    &:hover {
      text-decoration: underline;
    }
  }
}

@media only screen and (max-width: $breakpoint-lg) {
  .form-status-menu {
    display: none;
  }
}

.form-status-menu__mobile {
  display: none;
}

.form-status-menu__mobile {
  display: block;
  position: sticky;
  background-color: #FFF;
  top: 0;
  padding-top: 1rem;
  padding-bottom: 1.5rem;
  z-index: 1;
}

.timeline {
  h2 {
    font-size: 1rem;
    @media only screen and (min-width: $breakpoint-md) {
      font-size: 1.25rem;
    }
  }

  &__step-description {
    margin-top: 1.25rem;
  }
  &:focus-visible {
    outline: none;
  }

  &__course {
    width: 100%;
    margin-top: 1.5rem;
    @media only screen and (min-width: $breakpoint-md) {
      width: 22.5rem;
    }
  }

  &__read-btn {
    margin-top: 1rem;
  }
}

.progressbar {
  padding-bottom: 1rem;
}

.form-status__referralparties {
  grid-column: 2;
  max-width: 500px;
  &__title {
    margin-top: 0;
    grid-column: 2; 
  }
}

.form-status__consent {
  margin-bottom: 2rem;
  &>h3 {
    margin-bottom: 2rem;
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
  &-item {
    padding: 0;
    list-style: none;
  }
  &-card {
    border-radius: $border-radius--medium;
    border: 1px solid $in--black-25;
    padding: 1.5rem;  
    width: 100%;
    margin-bottom: 1.25rem;
    &-paragraph {
      font-size: 1.25rem;
      line-height: 1.5rem;
      font-weight: 700;
      margin: 0;
      }
  }
}