@import "../../styles/variables.scss";

.form-status__feedback-wrapper {
    background-color: $in--blue-12;
    padding: 2rem;
    text-align: center;
    & p {
        margin-bottom: 1.25rem;
    }
    margin-bottom: 2.5rem;
}

.feedback {
  &__stars div {
    justify-content: center;
  }

  &__thank-you-message {
    display: inline-block;
  }
}
