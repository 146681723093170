@import '../../styles/variables.scss';

.home {
  display: grid;
  grid-column: span 4;

  @media only screen and (min-width: $breakpoint-md) {
    grid-column-start: 1;
    grid-column-end: 9;
  }

  @media only screen and (min-width: $breakpoint-lg) {
    grid-column-start: 1;
    grid-column-end: 9;
  }
  &__info {
    &--title {
      margin: 0;
    }
    &--subtitle {
      @media screen and (min-width: $breakpoint-md) {
        font-size: 24px;
      }
      color: $color--grey-600;
      margin-bottom: 6rem;
    }
  }
}
