.inlineReadOnlyTextField {
  display: flex;
  flex-flow: nowrap;
  flex-direction: row;
  justify-content: space-between;
}
.inlineReadOnlyTextField {
  line-height: 1.5rem;
  font-size: 1rem;
}
.inlineReadOnlyTextField-value {
  font-weight: 600;
}