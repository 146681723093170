@import '../../styles/variables.scss';
@import '../../styles/breakpoints.scss';

hr {
  border-top: 1px solid #bfbfbf;
  margin-bottom: 2rem;
  @media only screen and (min-width: $breakpoint-md) {
    margin-bottom: 2.5rem;
    margin-top: 0.5rem;
  }
}

.messages {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  @media only screen and (min-width: $breakpoint-md) {
    margin-bottom: 2rem;
  }

  &__wrapper {
    max-width: 500px;
    &--right {
      align-self: flex-end;
    }
  }

  &__header {
    max-width: 500px;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5rem;
    text-transform: capitalize;
    &--dato {
      float: right;
      font-weight: 400;
    }
  }

  &--column {
    justify-content: space-between;
  }

  &--none {
    max-width: none;
  }

  &__bubble {
    background-color: #f2f2f2;
    max-width: 500px;
    border-radius: 0.5rem;
    padding: 0.5rem;
    @media only screen and (min-width: $breakpoint-md) {
      padding: 1rem;
    }

    &--right {
      background-color: #d6f6ff;
    }
  }
}
