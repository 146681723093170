.form-actions{
  padding-top:2rem;
  .form-button {
    display:inline-block;
  }
}

.validationTitle {
  font-weight: 600;
  display:block;
}

.validationTitle.extraSpace {
  padding-bottom: 1rem;
}

.form-formtype{
  padding-bottom: 0.625rem;
}