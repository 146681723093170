@import '../../../styles/variables.scss';

.fieldType.textField,
.fieldType.radioButtonField {
  color: map-get($colors, 'black-50');
}

.formSummaryPage {
  &:not(:last-child) {
    margin-bottom: 2rem;
  }
}

h3.h3AdjustedForSummaryPage {
  font-size: 1.3rem;
  font-weight: 700;
}

.formSummaryField .summaryFieldLabel {
  font-weight: 600;
}
