@import '../styles/variables.scss';

.footer {
  padding: 3.125rem 0;

  p {
    font-weight: 400;
    font-size: 1rem;
    line-height: 200%;
  }

  h3 {
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 120%;

    @media only screen and (min-width: $breakpoint-md) {
      font-size: 1.5rem;
    }
  }

  h3,
  p {
    margin-bottom: 0.625rem;
  }
  p:last-of-type {
    margin-bottom: 0;
  }

  button {
    margin-top: 0.625rem;
  }

  & > div > div > div {
    margin-bottom: 3rem;
    &:first-of-type {
      margin-top: 0;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
    @media only screen and (min-width: $breakpoint-md) {
      margin-bottom: 1rem;
    }

    &:first-of-type {
      margin-top: 1rem;
    }
    &:last-of-type {
      margin-bottom: 1rem;
    }
  }
}
