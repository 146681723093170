/**
 * Breakpoints
 */

 @mixin breakpoint-xs {
    @content;
  }
  
  @mixin breakpoint-sm {
    @media screen and (min-width: $layout-breakpoint-sm) {
      @content;
    }
  }
  
  @mixin breakpoint-sm-max {
    @media screen and (max-width: $layout-breakpoint-sm) {
      @content;
    }
  }
  
  @mixin breakpoint-md {
    @media screen and (min-width: $layout-breakpoint-md) {
      @content;
    }
  }
  
  @mixin breakpoint-lg {
    @media screen and (min-width: $layout-breakpoint-lg) {
      @content;
    }
  }
  