h2.h2AdjustedForTopBorder {
  margin: 0rem 0rem 3rem;
  font-size: 2rem;
  line-height: 2rem;
}

.form-field .fieldLabel {
  display: block;
  font-weight: 600;
  line-height: 1.5rem;
  padding-bottom: 0.5rem;
}

.pageValidationMessageTop {
  margin-bottom: 2rem;
}

.formPageValidationSummary {
  margin-top: 1rem;
  margin-bottom: 2rem;

  ol {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
  }
}

.notificationWrapper {
  margin-top: 2rem;
}

.description p {
  margin-bottom: 3rem;
}

.description li > p {
  margin-bottom: 0;
}

.description ul {
   // Workaround for margins on paragraphs being too large before a list
  margin-top: -2rem;
}