.groupSummaryField {
  .formSummaryField {
    margin-bottom: 0;
  }

  .summaryFieldLabel {
    color: black;
    display: block;
    font-weight: 600;
    padding-bottom: 0.5rem;
  }
}