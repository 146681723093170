@import '../../styles/variables';

.save-bar {
  background-color: $in--black-12;
  padding: 0.4rem;
  margin-bottom: -40px;
  z-index: 1;

  &__saveText {
    float: right;

    &--checkmark {
      color: $in--green-200;
      margin-right: 0.5rem;
    }

    &--spinner {
      -webkit-animation: spin 700ms linear infinite;
      -moz-animation: spin 700ms linear infinite;
      animation: spin 700ms linear infinite;
      margin-right: 0.5rem;
    }
  }

  &--fixed {
    position: fixed;
    width: 100vw;
    top: 0;
  }

  &--buffer {
    margin-bottom: 40px;
  }
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
