@import '../../styles/variables.scss';

.formSummaryField {
  margin-bottom: 1.5rem;

  .formSummaryField-card {
    border-radius: 0.5rem;
    padding: 1.5rem;
    border: 0.0625rem solid $in--black-25;

    &.fieldError {
      border-color: $in--red-100;
      margin-bottom: 0.5rem;
    }

    div,
    p {
      margin-bottom: 0;
    }
  }

  .inlineReadOnlyTextField {
    padding-bottom: 0.3rem;
  }
}